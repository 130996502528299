@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before, .slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*********************************

slick-theme.css

*********************************/
/* Slider */
.slick-loading .slick-list {
  background: #fff; }

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 39%;
  display: block;
  width: 11px;
  height: 16px;
  padding: 0;
  margin-top: -10px;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 999; }

.top_section02 .slick-prev,
.top_section02 .slick-next {
  top: 38%; }

.top_section06 .slick-prev,
.top_section06 .slick-next {
  top: 32%; }

.top_section10 .slick-prev,
.top_section10 .slick-next {
  top: 28%; }

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: transparent;
  outline: none;
  /*background: transparent;*/
  opacity: 0.8; }

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -20px;
  background-size: 11px 16px; }

[dir='rtl'] .slick-prev {
  right: -20px;
  left: auto; }

/*.slick-prev:before
{
    content: '遶奇ｿｽ';
}
[dir='rtl'] .slick-prev:before
{
    content: '遶奇ｿｽ';
}*/
.slick-next {
  right: -20px;
  background-size: 11px 16px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -20px; }

/*.slick-next:before
{
    content: '遶奇ｿｽ';
}
[dir='rtl'] .slick-next:before
{
    content: '遶奇ｿｽ';
}*/
/* Dots */
.slick-slider {
  margin-bottom: 0; }

.top_section06.slick-initialized.slick-slider {
  margin-bottom: 0; }

.slick-dots {
  position: absolute;
  bottom: -38px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0 !important;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 0 4px;
  padding: 0;
  background: #c4c4c4;
  border-radius: 10px;
  cursor: pointer; }

.slick-dots li.slick-active {
  background: #dea398; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  /*content: '遯ｶ�｢';*/
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

@media screen and (max-width: 740px) {
  .slick-prev, .slick-next {
    width: 28px;
    height: 79px;
    background-size: 28px 79px; }
  .slick-prev {
    left: 0px; }
  .slick-next {
    right: 0px; }
  .slick-slider {
    padding-bottom: 10%; }
  .slick-dots {
    bottom: 0px; }
  .slick-dots li {
    width: 10px;
    height: 10px; } }
